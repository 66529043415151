/* App.css */
.text-center {
  text-align: center;
}

.m-10 {
  margin: 10px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.w-full {
  width: 100%;
}
